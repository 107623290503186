export const MenuItems = [
    {
        title : "Inicio",
        url : "/",
        cName : "nav-links",
        icon : "fa-sharp fa-solid fa-house"

    },
    {
        title : "Nosotros",
        url : "/nosotros",
        cName : "nav-links",
        icon : "fa-solid fa-circle-info"

    },
    {
        title : "Contacto",
        url : "/contacto",
        cName : "nav-links",
        icon : "fa-solid fa-address-book"

    }

];