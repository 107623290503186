import Hero from "../components/Hero";
import Navbar from "../components/Navbar"
import AboutImg from "../assets/6.avif"
import Footer from "../components/Footer"
import ContactForm from "../components/ContactForm";

function Contacto(){
    return(
        <>
         <Navbar />
          <Hero 
            cName="hero-mid"
            heroImg={AboutImg}
            title="Contacto"  
          />
          <ContactForm/>
          <Footer/>
        </>

    );

}


export default Contacto;