import "./AboutUsStyles.css"

function AboutUs(){
    return(
        <div className="about-container">
            <h1>Sobre Nosotros</h1>
            <p>Somos una empresa de transporte de carga con algunos años de experiencia en el mercado chileno. Contamos con un equipo de profesionales altamente capacitados y comprometidos con la excelencia en el servicio. Nuestra flota de camiones Renault y Volkswagen, equipados con tecnología de punta, nos permite ofrecer soluciones de transporte eficientes y confiables a nuestros clientes. Utilizamos sistemas GPS y de gestión de flotas para monitorear y optimizar nuestras operaciones en tiempo real, garantizando la seguridad y la puntualidad en todas nuestras entregas".<br/><br/>
Tecnologías y sistemas:<br/><br/>
1.	GPS y sistemas de seguimiento en tiempo real.<br/>
2.	Sistemas de gestión de flotas y mantenimiento preventivo.<br/>
3.	Tecnologías de ahorro de combustible y reducción de emisiones contaminantes.<br/>
4.	Capacitación y desarrollo continuo de nuestros choferes en el uso de tecnologías y buenas prácticas de conducción.

</p>
            <h1>Mision</h1>
            <p>Ofrecer soluciones líderes en transporte y logística, enfocadas en el cliente y la excelencia, siendo un socio estratégico comprometido con el servicio de calidad y el cuidado ambiental.</p> 
            <h1>Vision</h1>
            <p>Afianzarnos como empresa líder en transporte y logística, ofreciendo soluciones profesionales y orientadas al cliente. Ser un socio estratégico, comprometidos con la excelencia en servicio y el cuidado del medio ambiente.</p>
            <h1>Valores:</h1>
            <p>1. Compromiso Genuino: Total dedicación a nuestras promesas y clientes.<br/>
2.	Trabajo Colaborativo: Fomentamos la cooperación para lograr la excelencia.<br/>
3.	Responsabilidad y Honestidad: Actuar con integridad y transparencia en todas nuestras operaciones.<br/>

</p>
        </div>
    )
}

export default AboutUs