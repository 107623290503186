import Footer from "../components/Footer"
import Destination from "../components/Destination";
import Hero from "../components/Hero";
import Navbar from "../components/Navbar";

function Home(){
    return(
        <>
           <Navbar />
          <Hero 
            cName="hero"
            heroImg="https://images.unsplash.com/photo-1486673748761-a8d18475c757?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1170&q=80"
            title="TMH Transportes"
            text="Transportes Morales e Hijos"
            
          />
          <Destination/>
          <Footer/>
        </>
    );

}


export default Home;