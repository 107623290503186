import Camiones1 from "../assets/3.avif"
import Camiones2 from "../assets/1.avif"
import Camiones3 from "../assets/5.avif"
import Camiones4 from "../assets/6.avif"
import DestinationData from "./DestinationData";
import "./DestinationStyles.css";

const Destination = () =>{
    return(
    <div className="destination">
        <h1>Transportes Morales e Hijos</h1>
        <p>Bienvenido a TMH, líderes en soluciones de transporte de camiones en todo el país. Nos especializamos en la logística de carga y transporte, conectando puertos, empresas y destinos a lo largo y ancho de nuestra hermosa nación.</p>

        <DestinationData

        className="first-des"
        
        
        text="En TMH, entendemos que cada negocio tiene necesidades únicas de transporte, por lo que ofrecemos soluciones personalizadas y flexibles para satisfacer sus requerimientos específicos. Nuestra amplia experiencia y red de colaboradores nos permite garantizar un servicio eficiente, seguro y puntual.
Trabajamos incansablemente para asegurar que sus productos lleguen a su destino en perfectas condiciones y a tiempo, para que usted pueda enfocarse en lo que mejor sabe hacer: hacer crecer su negocio."
        img1={Camiones1}
        img2={Camiones2}
        />

<DestinationData

    className="first-des-reverse"
    heading="Nuestros servicios incluyen"

    text="Transporte terrestre de camiones desde y hacia puertos, con la opción de carga completa o parcial. Coordinación y seguimiento del envío en tiempo real. Gestión de trámites aduaneros y documentación requerida. Servicio de almacenamiento y distribución. Seguro de carga y asesoramiento en temas de seguridad."
    img1={Camiones3}
    img2={Camiones4}
/>
    </div>
    )
}

export default Destination