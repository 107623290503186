import "./ContactFormStyles.css";
import React, { useRef } from "react";
import emailjs from '@emailjs/browser';


function ContactForm(){
    const refForm = useRef();
    const handleSubmit = (event) =>{
        event.preventDefault();

       const serviceId= "service_ekqc8nm";
       const templateId= "template_bzzxkxi";
       const apikey = "C7ZccF1jFx3eRzayc";

       emailjs.sendForm(serviceId, templateId,refForm.current,apikey)
       .then(result => console.log(result.text))
       .catch(error => console.error(error))

    }
    return(
        <div className="from-container">
            <h1>Envianos un mensaje</h1>
            <form ref={refForm} action= "" onSubmit={handleSubmit}>
                <input name="username" placeholder="Name" required/>
                <input name="email" id="email" placeholder="Email" type="email" required/>
                <input placeholder="Asunto"/>
                <textarea name="message" id="" placeholder="Mensaje" rows="4"></textarea>
                <button className="btn-send">Enviar Mensaje</button>
            </form>
        </div>
    )
}

export default ContactForm