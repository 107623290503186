import Hero from "../components/Hero";
import Navbar from "../components/Navbar"
import AboutImg from "../assets/4.avif"
import Footer from "../components/Footer"
import AboutUs from "../components/AboutUs"

function Nosotros(){
    return(
        <>
            <Navbar />
          <Hero 
            cName="hero-mid"
            heroImg={AboutImg}
            title="Sobre Nosotros"  
          
          />
          <AboutUs />
           <Footer/>
        </>
    );

}


export default Nosotros;