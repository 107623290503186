import "./FooterStyles.css"
import logo from '../assets/Logo TMH - Negativo.png';

const Footer = () =>{

    return(
        <div className="footer">            
            <div className="top">
                <div>
                    <p>Transportes Morales e Hijos</p>
                    <img className="logo" src={logo} alt="Logo de la empresa" />
                </div>
                <div></div>
                <div>
                    <h1>
                        Correo: o.morales@transportesmorales.cl<br/>
                        Celular: +56 9 8469 6318
                    </h1>

                    <a href="/">
                        <i className="fa-brands fa-facebook-square"></i>
                    </a>
                    <a href="/">
                        <i className="fa-brands fa-instagram-square"></i>
                    </a>
                    <a href="/">
                        <i className="fa-brands fa-twitter-square"></i>
                    </a>
                   
                </div>
            </div>


            
        </div>
    )
}

export default Footer