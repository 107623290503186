import "./styles.css";
import { Route, Routes } from "react-router-dom";
import Home from "./routes/Home";
import Nosotros from "./routes/Nosotros";
import Contacto from "./routes/Contacto";


export default function App(){
  return (
   <div className="App"> 
      <Routes>
        <Route path="/" element={  <Home /> } /><Route path="/nosotros" element={  <Nosotros /> } /><Route path="/contacto" element={  <Contacto /> } />
      </Routes>

        
   </div>
  );

  
}